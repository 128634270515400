import { Component, Inject, LOCALE_ID, Renderer2, OnInit } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { EncrDecrService } from './services/encr-decr-service.service';
import cupon from '@iconify/icons-fa-solid/paper-plane';
import motorcycle from '@iconify/icons-fa-solid/motorcycle';
import user from '@iconify/icons-fa-solid/user-astronaut';
import notificacion from '@iconify/icons-fa-solid/sticky-note';
import cliente from '@iconify/icons-fa-solid/user-friends';
import icBusiness from '@iconify/icons-ic/twotone-business';
import categorias from '@iconify/icons-fa-solid/clipboard-list';
import campanias from '@iconify/icons-fa-solid/campground';
import costos from '@iconify/icons-fa-solid/money-check-alt';
import { AuthserviceService } from './services/authservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import reportes from '@iconify/icons-fa-solid/registered';
import card from '@iconify/icons-fa-solid/id-card';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vex';
  decrypted: any;
  id: any;

  constructor(private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private EncrDecr: EncrDecrService,
    private splashScreenService: SplashScreenService,
    private service: AuthserviceService,
    private snackbar: MatSnackBar,
    public router: Router) {
    if (localStorage.getItem('log')) {
      this.decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem('log'));
      this.id = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem('keylog'));
    }
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }


    this.configService.updateConfig({
      sidenav: {
        title: 'Delivery Flu Food',
        imageUrl: './assets/img/icon.png',
        showCollapsePin: true,
      },
      footer: {
        visible: false
      }
    });

    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    if (this.decrypted == 'Administrador') {
      this.navigationService.items = [
        {
          type: 'link',
          label: 'Dashboard',
          route: '/',
          icon: icLayers,
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            {
              type: 'link',
              label: 'Users',
              route: '/apps/users',
              icon: user
            },
            {
              type: 'link',
              label: 'Clientes',
              route: '/apps/clientes',
              icon: cliente
            },
            {
              type: 'link',
              label: 'Repartidores',
              route: '/apps/repartidores',
              icon: motorcycle
            },
            {
              type: 'link',
              label: 'Categorias',
              route: '/apps/categorias',
              icon: categorias
            },
            {
              type: 'link',
              label: 'Restaurantes',
              route: '/apps/restaurantes',
              icon: icBusiness
            },
            {
              type: 'link',
              label: 'Costos',
              route: '/apps/costos',
              icon: costos
            },
            {
              type: 'link',
              label: 'Porcentaje pago',
              route: '/apps/porcentaje',
              icon: card
            },
            {
              type: 'link',
              label: 'Notificaciones',
              route: '/apps/notificaciones',
              icon: notificacion
            },
            {
              type: 'link',
              label: 'Cupones',
              route: '/apps/cupones',
              icon: cupon
            },
            {
              type: 'link',
              label: 'Campañas',
              route: '/apps/campanias',
              icon: campanias
            },
            {
              type: 'link',
              label: 'Reportes',
              route: '/apps/reportes',
              icon: reportes
            },
          ]
        },
      ];
    }
  }

  ngOnInit() {
    window.addEventListener("storage", function () {
      localStorage.clear();
      window.location.reload();
    }, false);
    this.hora();
    setInterval(() => {
      this.hora();
    }, 70000);

    this.show();
    this.del_list();
    this.del_cupones();
    this.inicioCosto();
    this.finCosto();
    this.exp_campania();
    setInterval(() => {
      this.show();
      this.del_list();
      this.del_cupones();
      this.exp_campania();
    }, 60000);
    setInterval(() => {
      this.inicioCosto();
      this.finCosto();
    }, 30000);

  }

  hora(): void {
    var ahora = new Date();
    var hora = ahora.getHours();
    if (hora >= 6 && hora < 18) {
      this.styleService.setStyle(Style.default);
    } else {
      this.styleService.setStyle(Style.dark);
    }
  }

  show(): void {
    this.service.show(this.id).subscribe((data) => {
      if (data.data.verified == 0) {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.message('Usuario Deshabilitado');
      }
    });
  }

  del_list(): void {
    this.service.deleteMenu().subscribe((data) => {
    });
  }

  del_cupones(): void {
    this.service.deleteCupones().subscribe((data) => {
    });
  }

  inicioCosto(): void {
    this.service.inicioCosto().subscribe((data) => {
    });
  }

  finCosto(): void {
    this.service.finCosto().subscribe((data) => {
    });
  }

  exp_campania(): void {
    this.service.expCampania().subscribe((data) => {
    });
  }

  message(m): void {
    this.snackbar.open(m, 'Cerrar', {
      duration: 4000
    });
  }
}
