import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  private objectSource = new BehaviorSubject<{}>({});
  httpOptions: { headers: HttpHeaders; };
  apiUrl: string;
  urls: string;

  constructor(private http: HttpClient) {
    // this.apiUrl = "http://apiservicedelivery.test/";
    this.apiUrl = "https://api.flufoodapp.com/";
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  loginUser(username, password): Observable<any> {
    this.urls = this.apiUrl + "api/login";

    var datoaEnviar = {
      "name": username,
      "password": password
    }
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  show(id): Observable<any> {
    this.urls = this.apiUrl + "api/show/" + id;
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteMenu(): Observable<any> {
    this.urls = this.apiUrl + "api/del-list";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteCupones(): Observable<any> {
    this.urls = this.apiUrl + "api/exp-cupones";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  inicioCosto(): Observable<any> {
    this.urls = this.apiUrl + "api/start-costo";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  finCosto(): Observable<any> {
    this.urls = this.apiUrl + "api/exp-costo";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  expCampania(): Observable<any> {
    this.urls = this.apiUrl + "api/exp-campanias";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

}
